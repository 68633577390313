.fullcontainer {
  background: #f3f3f3;
  min-height: 70vh;
  padding: 10px 0;
}

.container {
  border: 1px solid #d0d0d0;
  background: #ffffff;
  padding: 20px 10px;
}

.sidecontainer {
  border-bottom: 1px solid #d0d0d0;
  padding: 10px;
}

.form {
  border: 1px solid #d0d0d0;
  padding: 10px;
}

.inputfield {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  height: 40px;
  padding: 0 10px;
}

.inputfieldtextarea {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  resize: none
}

.button{
  margin-top: 0 !important;
}

.icon{
  font-size: 28px;
  margin-right: 7px;
  color: #102c44;
}

.professionalTitle{
  color: #102c44;
}

@media screen and (max-width: 768px) {
  .about_side {
    display: none;
  }
  .about_main {
    margin: 0;
  }
}