.sideDiv{
    border: 1px solid #d0d0d0;
    padding: 0 0;
}

.sideDiv h1{
    font-size: 20px;
    font-weight: 550;
    padding: 13px 15px;
}

.sideDiv h2{
    font-size: 16px;
    font-weight: 550;
    border-top: 1px solid #d0d0d0;
    padding: 10px 15px;
    
}


