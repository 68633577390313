.whatsapp-float-button {
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 50px;
  /* Adjust the size as needed */
  height: 50px;
  /* Adjust the size as needed */
  background-color: #25d366;
  /* WhatsApp green color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  /* Animation for hover effect */
}

.whatsapp-float-button:hover {
  transform: scale(1.1);
  /* Slightly enlarges the button on hover */
}

.whatsapp-float-button img {
  width: 50%;
  /* Ensures the icon fits well within the button */
  height: 50%;
}