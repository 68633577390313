.vendor_from{
    margin: 10px 0;
    border: 1px solid #d0d0d0;
}

.vendor_from_title{
    border-bottom: 1px solid #d0d0d0;
}

.vendor_input{
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  height: 45px;
  padding: 0 10px;
}

.vendor_textarea{
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    resize: none;
}