.fullcontainer {
  background: #f3f3f3;
  min-height: 70vh;
  padding: 10px 0;
}

.container {
  border: 1px solid #d0d0d0;
  background: #ffffff; 
  padding: 20px 10px;
}

.service_card{
  /* -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0))) */
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}

.service_img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card_text_bg {
  padding: 50px 0 0 0;
  /* /* background-color: #fff; */
  background-image: linear-gradient(to bottom,transparent,#ffffffe4, #ffffff);
}

@media screen and (max-width: 768px) {
  .about_side {
    display: none;
  }
  .about_main {
    margin: 0;
  }
}
