.offers__container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  background: rgba(0, 0, 0, 0.719);
  backdrop-filter: blur(2px);
}

.header{
    position: absolute;
    top: 27.5%;
    z-index: 11;
    width: 500px;
}

.offers_card {
  background: white;
  max-width: 80%;
  padding: 0 100px;
  /* margin: 15px 15px; */
  border-radius:10px 5px 5px 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.719);
  min-width: 300px;
  max-height: 45%;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
    margin: auto;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #565656;
    border-radius: 5px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #686868e1;
  }
}


.offers_card_item{
    /* margin: 15px 0; */
    padding: 25px;
    background-color: #102c44;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.719);
    width: 450px;
}

@media screen and (max-width: 768px) {
  .offers_card{
    padding: 0 20px;
  }
  .offers_card_item{
    padding: 15px;
  }
  .header{
    width: 350px;
  }

  .offers_card_item{
    width: auto;
  }
}

@media screen and (max-width: 460px) {
.header{
  width: 320px;
}
}

@media screen and (max-width: 430px) {
.header{
  width: 305px;
}
}

@media screen and (max-width: 400px) {
.header{
  width: 280px;
}
}
