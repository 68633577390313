.fullcontainer {
  background: #f3f3f3;
  min-height: 70vh;
  padding: 10px 0;
}

.container {
  border: 1px solid #d0d0d0;
  background: #ffffff;
  padding: 20px 10px;
}

.sidecontainer {
  border-bottom: 1px solid #d0d0d0;
  padding: 10px;
}

.orderDiv{
  height: 150px;
}

.imgdiv{
  margin: 15px 10px 15px 10px;
  width: 130px;
  height: 120px;
}

@media screen and (max-width: 998px) {
  .orderDiv{
    height: 160px;
  }
  .imgdiv{
    margin: 7px;
    width: 65px;
    height: 100px;
  }

  .descriptionDiv{
    margin: 12px;
  }
}

.imgdiv img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.descriptionDiv{
  margin: 20px;
}
