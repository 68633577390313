/* Style for the circular open button */
.open-button {
  position: fixed;
  bottom:110px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007BFF;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease; /* Animation for hover effect */
}

.open-button-icon {
  color: white;
  font-size: 24px;
  font-weight: bold;
  line-height: 0; /* Center the icon vertically */
}

.open-button:hover {
  transform: scale(1.1);
}

/* The Modal (background) */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* The Close Button */
.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
}

/* Form styling */
.form-container {
  display: flex;
  flex-direction: column;
}

.form-container h2 {
  text-align: center;
  margin-bottom: 1em;
}

.form-container label {
  margin-bottom: 1em;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 0.8em;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures that padding is included in width */
}

.form-container input:focus,
.form-container textarea:focus {
  border-color: #007BFF;
  outline: none;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancel-button,
.submit-button {
  padding: 0.8em 1.2em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.submit-button {
  background-color: #007BFF;
  color: white;
}

.submit-button:hover {
  background-color: #0056b3;
}
