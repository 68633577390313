.contactcart{
    border: 1px solid #d0d0d0;
}

.Contactcard_title{
    border-bottom: 1px solid #d0d0d0;
}

.contactcard_subcont{
    margin: 10px
}