.logo{
    width: 50%;
    margin-bottom: 15px;
}

.footer_parent{
    padding: 40px 0;
}

.second_div{
    margin-left: 150px;
}

@media screen and (max-width: 600px){
    .footer_parent{
        padding: 40px 0;
    }
}

@media screen and (max-width: 896px){
    .second_div{
        margin-left: 0px;
    }
}



.footer_div{
    color: #fff;
    padding: 10px;
}

.footer_div p{
    font-size: 15px;
    margin: 10px;
}


.footer_div h3{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.footer_div ul{
    list-style: none;
    margin-left: 2px;
}

.footer_div ul li{
    font-size: 16px;
    padding: 5px
}

.footer_socials{
    padding: 10px;
}

.footer_socials i{
    color: #fff;
    font-size: 35px;
    padding: 10px;
    cursor: pointer;
    transition: 0.7s ease;
}

.footer_socials i:hover{
    color: #F7D10C;
}