.input_group {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 2.5%;
  width: 100%;
}

.input_group input {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  padding: 0 40px 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  border-radius: 5px;
}

.searchicon {
  position: absolute;
  right: 0;
  height: 100%;
  border-radius: 0 5px 5px 0;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 10px 0 10px;
  transition: 0.5s ease;
  color: #102c44;
  border-left: 1px solid #102c4460;
}

.dropdownresults {
  z-index: 1;
}

.search_li {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 998px) {
  .input_group{
    margin: 0;
  }
}
