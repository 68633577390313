.fullcontainer {
  background: #f3f3f3;
  min-height: 70vh;
  padding: 10px 0;
}

.container {
  border: 1px solid #d0d0d0;
  background: #ffffff;
  padding: 20px 10px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin: 25px 0;
}

.title h1 {
  color: #06121bf3;
}

.title span {
  font-size: 24px;
  font-weight: 300;
}

.wishlistproductdiv {
  position: relative;
}

.crossicon {
  position: absolute;
  right: 4%;
  top: 2%;
  background: #ffffff;
  cursor: pointer;
  border-radius: 50%;
}

.crossicon .icons {
  font-size: 30px;
  padding: 5px;
}

.img_container {
  border: 1px solid #7d7d7d92;
  border-bottom: none;
  padding: 10px;
}

.text_container {
  padding: 5px 10px;
  border: 1px solid #7d7d7d92;
  border-top: none;
}

.productname {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wishlistproductdiv h4 {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-size: 18px;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  /* padding: 5px 0 20px 0; */
  padding: 5px 10px;
}

.imagesdiv {
  width: 100%;
  height: 400px;
}

@media (max-width: 998px) {
  .imagesdiv {
    width: 100%;
    height: 250px;
  }
}

.imagesdiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cartButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  border: 1px solid #d0d0d0;
  font-size: 18px;
  font-weight: 900;
  color: #06121bf3;
}

.priceSpan {
  margin-left: 15px;
  font-size: 16px;
}
