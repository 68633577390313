.fullcontainer {
  background: #f3f3f3;
  min-height: 70vh;
  padding: 10px 0;
}

.container {
  border: 1px solid #d0d0d0;
  background: #ffffff;
  padding: 20px 10px;
}

.vendor_main {
  margin: 0 0 0 10px;
}

.vendor_main h1 {
  font-size: 30px;
  font-weight: 550;
  color: #102c44;
}

.hero img {
  /* height: 65vh; */
  width: 100%;
  object-fit: cover;
}

.vendor_from {
  margin: 10px 0;
  border: 1px solid #d0d0d0;
  border-radius: 7px;
}

.vendor_from_title {
  border-bottom: 1px solid #d0d0d0;
}

/* .vendor_input{
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  height: 45px;
  padding: 0 10px;
}

.vendor_textarea{
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    resize: none;
} */

@media screen and (max-width: 768px) {
  .vendor_side {
    display: none;
  }
  .vendor_main {
    margin: 0;
  }

  .hero img {
    /* height: 40vh; */
  }
}
