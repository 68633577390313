.fullcontainer {
  background: #f3f3f3;
  min-height: 90vh;
  padding: 10px 0;
}

.container {
  border: 1px solid #d0d0d0;
  background: #ffffff;
  padding: 20px 10px;
}

.sideTable {
  border: 1px solid #d0d0d0;
  padding: 13px 15px;
}

.headtitle {
  font-size: 30px;
  font-weight: 550;
  color: #102c44;
  height: fit-content !important;
}

.productCard {
  border: 1px solid #d0d0d0;
  width: 100%;
  /* height: 97%; */
  display: flex;
  padding: 0 15px;
  /* align-items: center; */
  flex-direction: column;
}

.breakcrumb_overflow {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.breakcrumb_overflow ::-webkit-scrollbar {
  display: none;
}

.imageDiv {
  margin-top: 15px;
  width: 100%;
  height: 20vh;
}

.productCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: center; */
}

.productCard h3 {
  /* margin: 15px; */
  font-size: 16px;
  font-weight: 500;
}

.productPrice {
  color: #102c44;
}

.mobilefilter {
  display: none;
}

.productname {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  /* margin: 0 auto; */
  /* font-weight: 500; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .sideTable {
    display: none;
  }
  .mobilefilter {
    display: flex;
    /* position: fixed; */
  }
  .fixdiv {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: #ffffff;
    /* padding: 0 0 5px 0; */
    /* border-bottom: 1px solid #d0d0d0; */
  }
  .fullcontainer {
    padding: 0;
  }
  .container {
    padding: 0;
  }
  .headtitle {
    font-size: 23px;
  }
}

@media screen and (max-width: 450px) {
  .imageDiv {
    height: 15vh;
  }
}

.mobfiltercard {
  transform: translateY(200%);
}
