.changeaddress_window{
    position: absolute;
    display: none;
    background: #00000047;
    height: 100vh;
    /* height: max-content; */
    /* max-height: 100vh; */
    width: 100vw;
    z-index: 14;
}

.active{
    display: flex;
    justify-content: start;
    align-items: flex-end;
    flex-direction: column;
}

.changeaddress{
    transform: translateX(130%);
    /* z-index: 13; */
    background-color: rgb(255, 255, 255);
    height: 100vh;
    max-height: 100vh;
    /* overflow-y: auto; */
    padding: 30px 30px;
    width: 350px;
    transition: 1s ease;

    /* transition-delay: 0.1s; */
}

.changeaddress.activee{
    /* transition: 1s ease; */
    transform: translateX(0%);
    transition: 1s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}