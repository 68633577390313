.fullcontainer {
  background: #f3f3f3;
  min-height: 70vh;
  padding: 10px 0;
}

.container {
  border: 1px solid #d0d0d0;
  background: #ffffff;
  padding: 20px 10px;
}

.about_main {
    margin: 0 0 0 10px;
}

.about_main h1 {
    font-size: 30px;
    font-weight: 550;
    color: #102c44;
}


@media screen and (max-width: 768px) {
  .about_side {
    display: none;
  }
  .about_main {
    margin: 0;
  }
}